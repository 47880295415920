<template>
  <section class="about-company">
    <div class="about-company__container _container">
      <div class="about-company__image">
        <picture>
          <source
            srcset="
              ../assets/images/company(585x440).jpg,
              ../assets/images/company(585x440)2x.jpg 2x
            "
            type="image/jpeg"
            media="(min-width: 1280px)"
          />
          <source
            srcset="
              ../assets/images/company(481x380).jpg,
              ../assets/images/company(481x380)2x.jpg 2x
            "
            type="image/jpeg"
            media="(min-width: 992px)"
          />
          <source
            srcset="
              ../assets/images/company(362x320).jpg,
              ../assets/images/company(362x320)2x.jpg 2x
            "
            type="image/jpeg"
            media="(min-width: 768px)"
          />
          <source
            srcset="
              ../assets/images/company(340x250).jpg,
              ../assets/images/company(340x250)2x.jpg 2x
            "
            type="image/jpeg"
            media="(max-width: 767px)"
          />
          <img
            src="../assets/images/company(585x440).jpg"
            alt="company"
            loading="lazy"
          />
        </picture>
      </div>
      <div class="about-company__text">
        <div class="about-company__header section__title">
          <div class="vertical-line"></div>
          <div class="about-company__title">
            {{ $t("about.title") }}
          </div>
        </div>
        <div v-html="$t('about.text')"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.about-company {
  &__container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 40px;
    padding-bottom: 40px;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__image,
  &__text {
    @include for-tablet-portrait-up {
      width: calc((100% - 14px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 30px) / 2);
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    height: 250px;
    @include for-tablet-portrait-up {
      height: 320px;
    }
    @include for-tablet-landscape-up {
      height: 380px;
    }
    @include for-desktop-up {
      height: 440px;
    }
    img,
    source {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      @include for-tablet-portrait-up {
        width: 100%;
      }
    }
  }
  &__text {
    font-family: "Manrope", sans-serif;
    @include P4();
    margin-bottom: 20px;
    @include for-tablet-portrait-up {
      margin-bottom: 0;
    }
    @include for-tablet-landscape-up {
      @include P3();
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
      @include for-desktop-up {
        margin-top: 20px;
      }
    }
  }
}
</style>
