<template>
  <section class="portfolio">
    <div class="portfolio__container _container">
      <div class="portfolio__screenshots">
        <VueSlickCarousel
          class="screenshots__slider"
          v-bind="images_settings"
          ref="images"
          :as-nav-for="$refs.text"
        >
          <div
            v-for="(items, index) in $t('portfolio.images')"
            :key="index"
            class="screenshots__container"
          >
            <img
              v-for="(item, index) in items"
              :key="index"
              :src="require(`../assets/images/${item}`)"
              alt="Screenshot of app"
            />
          </div>
        </VueSlickCarousel>
      </div>
      <div class="portfolio__apps">
        <div class="portfolio__header section__title">
          <div class="vertical-line"></div>
          <div class="portfolio__title">
            {{ $t("portfolio.title") }}
          </div>
        </div>
        <VueSlickCarousel
          class="projects__slider"
          v-bind="settings"
          ref="text"
          :as-nav-for="$refs.images"
        >
          <div
            v-for="(item, index) in $t('portfolio.projects')"
            :key="index"
            class="portfolio__items"
          >
            <div class="project__container">
              <div class="project__description">
                {{ item.project_description }}
              </div>
              <div class="project__title">
                {{ item.project_title }}
              </div>
              <TabView>
                <TabPanel
                  v-for="x of item.project_tabs"
                  :key="x.id"
                  :header="x.tab_title"
                >
                  <div class="slick-item__text" v-html="x.tab_content"></div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { TabView, TabPanel, VueSlickCarousel },
  data() {
    return {
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        arrows: true,
        draggable: false,
        swipe: false
      },
      images_settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        fade: true,
        draggable: false,
        swipe: false
      }
    };
  },
  mounted() {
    this.$nextTick(this.$forceUpdate);
  }
};
</script>

<style lang="scss">
.portfolio {
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__screenshots {
    width: 100%;
    margin-top: 60px;
    @include for-tablet-portrait-up {
      width: 50%;
      margin-top: 0;
    }
  }
  .screenshots__container {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 94.32px;
      height: 188.64px;
      &:nth-child(2) {
        width: 101.15px;
        height: 201.67px;
        @include for-tablet-portrait-up {
          width: 107.86px;
          height: 215.06px;
        }
        @include for-tablet-landscape-up {
          width: 144.56px;
          height: 288.23px;
        }
        @include for-desktop-up {
          width: 163px;
          height: 325px;
        }
      }
      @include for-tablet-portrait-up {
        width: 100.58px;
        height: 201.16px;
      }
      @include for-tablet-landscape-up {
        width: 134.8px;
        height: 269.61px;
      }
      @include for-desktop-up {
        width: 152px;
        height: 304px;
      }
    }
  }
  &__apps {
    width: 100%;
    @include for-tablet-portrait-up {
      width: calc(50% - 14px);
    }
    @include for-tablet-landscape-up {
      width: calc(50% - 20px);
    }
    @include for-desktop-up {
      width: calc(50% - 65px);
    }
  }
  .project {
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__description {
      font-family: "Manrope", sans-serif;
      color: $main-color;
      margin-bottom: 16px;
      @include P3-semi-bold();
      @include for-tablet-landscape-up {
        @include P1-semi-bold();
        margin-bottom: 20px;
      }
      @include for-desktop-up {
        @include H6-semi-bold();
        margin-bottom: 16px;
      }
    }
    &__title {
      font-family: "Manrope", sans-serif;
      color: $super-dark-gray-color;
      @include P4-semi-bold();
      margin-bottom: 24px;
      @include for-tablet-landscape-up {
        @include P4-semi-bold();
      }
      @include for-desktop-up {
        margin-bottom: 48px;
      }
    }
  }
  .p-tabview .p-tabview-nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    width: 315px;

    margin-bottom: 22px;
    @include for-tablet-landscape-up {
      margin-bottom: 24px;
      width: 425px;
    }
    @include for-desktop-up {
      margin-bottom: 32px;
    }
  }
  .p-tabview-nav-link {
    position: relative;

    font-family: "Manrope", sans-serif;
    color: $gray-color;
    @include P4();

    padding-bottom: 8px;
    white-space: nowrap;
    @include for-tablet-landscape-up {
      @include P2();
    }
    &::after {
      display: block;
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 0;
      height: 2px;
      border-radius: 4px;
      content: "";
      background: $main-color;
      transition: width 0.3s ease-out;
    }
  }
  .p-highlight > .p-tabview-nav-link {
    color: $black-color;
    &::after {
      width: 40px;
    }
    &::before {
      width: 40px;
    }
  }
  .p-tabview-panels {
    height: auto;
    font-family: "Manrope", sans-serif;
    color: $black-color;
    @include P6();
    @include for-tablet-landscape-up {
      @include P4();
    }
    p + p {
      margin-top: 10px;
    }
    .additional-information + .additional-information {
      margin-top: 10px;
    }
    .additional-information span {
      color: $main-color;
    }
    .other-information{
      margin-top: 10px;
    }
  }
  .screenshots__slider {
    height: 100%;
    .slick-list {
      overflow: hidden;
      height: 100%;
    }
    .slick-track {
      display: flex;
      height: 100%;
    }
    .slick-slide {
      div {
        height: 100%;
      }
    }
  }
  .projects__slider {
    position: relative;
    .slick-list {
      overflow: hidden;
    }
    .slick-track {
      display: flex;
    }
    .slick-arrow {
      font-size: 0;
      background: none;
      border: none;
      width: 60px;
      height: 10px;
      position: absolute;
      top: calc(100% + 25px);
      padding: 0;
    }
    .slick-arrow.slick-prev {
      left: 0;
      &::before {
        content: url("../assets/icons/prev.svg");
      }
    }
    .slick-arrow.slick-next {
      left: 72px;
      &::before {
        content: url("../assets/icons/next.svg");
      }
    }
  }
}
</style>
