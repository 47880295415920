<template>
  <section class="services">
    <div class="services__container _container">
      <div class="services__header section__title">
        <div class="vertical-line"></div>
        <div class="services__title">
          {{ $t("services.title") }}
        </div>
      </div>
      <div class="services__items">
        <VueSlickCarousel class="slider" v-bind="settings">
          <div
            class="services__item service"
            v-for="item of $t('services.items')"
            :key="item.id"
          >
            <div class="service__title">{{ item.title }}</div>
            <div class="service__subtitle" v-if="item.subtitle">
              {{ item.subtitle }}
            </div>
            <div class="service__description" v-html="item.text"></div>
            <div class="service__image">
              <img
                :src="require(`../assets/images/${item.image}`)"
                alt="Service number"
                loading="lazy"
              />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  data() {
    return {
      settings: {
        slidesToScroll: 1,
        slidesToShow: 3,
        infinite: true,
        arrows: true,
        draggable: true,
        swipe: true,
        variableWidth: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
              infinite: true,
              arrows: false,
              variableWidth: true
            }
          }
        ]
      }
    };
  },
  components: {
    VueSlickCarousel
  }
};
</script>

<style lang="scss">
.services {
  &__container {
    padding-top: 50px;
    padding-bottom: 40px;
    @include for-tablet-portrait-up {
      padding-top: 80px;
      padding-bottom: 55px;
    }
    @include for-tablet-landscape-up {
      padding-top: 117px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 110px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__items {
    min-width: 0;
  }
  .service {
    background: #fbfbfb;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 24px 14px 14px 14px;

    height: 340px;
    width: 237px;

    display: flex !important;
    flex-direction: column;

    @include for-tablet-portrait-up {
      height: 375px;
    }
    @include for-tablet-landscape-up {
      padding: 30px 20px 20px 20px;
      height: 430px;
    }
    @include for-desktop-up {
      padding: 30px 24px 24px 24px;
      height: 400px;
    }

    &__title {
      font-family: "Manrope", sans-serif;
      color: $main-color;
      @include P3-semi-bold();
      margin-bottom: 14px;
      @include for-tablet-landscape-up {
        @include P1-semi-bold();
        margin-bottom: 20px;
      }
      @include for-desktop-up {
        @include H5-semi-bold();
        margin-bottom: 24px;
      }
    }
    &__subtitle {
      font-family: "Manrope", sans-serif;
      @include P4-semi-bold();
      color: $super-dark-gray-color;
      margin-bottom: 10px;
      @include for-tablet-landscape-up {
        @include P3-semi-bold();
        margin-bottom: 12px;
      }
      @include for-desktop-up {
        @include P2-semi-bold();
        margin-bottom: 14px;
      }
    }
    &__description {
      font-family: "Manrope", sans-serif;
      color: $super-dark-gray-color;
      @include P6();
      @include for-tablet-landscape-up {
        @include P4();
      }
      a {
        color: $main-color;
      }

      ul {
        padding-left: 14px;
        list-style-position: outside;
        margin-top: 0;
        margin-bottom: 0;
        li {
          &::marker {
            color: $super-dark-gray-color;
            @include P7();
          }
        }
      }
    }

    &__image {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-grow: 1;
      img {
        width: 50px;
        height: 50px;
        @include for-tablet-landscape-up {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
  .slider {
    min-width: 0;
    position: relative;
  }
  .slick-list {
    overflow: hidden;
    padding: 0 25% 0 0 !important;
    // margin-left: -14px;
    margin-right: -10px;
    @include for-tablet-portrait-up {
      margin: 0 -14px;
      padding: 0 !important;
    }
    @include for-tablet-landscape-up {
      margin: 0 -21px;
    }
    @include for-desktop-up {
      margin: 0 -20px;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    margin: 0 14px;
    width: 237px;
    max-width: 237px;
    @include for-tablet-portrait-up {
      width: auto;
      max-width: none;
      margin: 0 14px;
    }
    @include for-tablet-landscape-up {
      margin: 0 21px;
    }
    @include for-desktop-up {
      margin: 0 20px;
    }
  }
  .slick-arrow {
    font-size: 0;
    background: none;
    border: none;
    width: 60px;
    height: 10px;
    position: absolute;
    top: -26px;
    padding: 0;
  }
  .slick-arrow.slick-prev {
    right: 72px;
    &::before {
      content: url("../assets/icons/prev.svg");
    }
  }
  .slick-arrow.slick-next {
    right: 0;
    &::before {
      content: url("../assets/icons/next.svg");
    }
  }
}
</style>
