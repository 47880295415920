<template>
  <section class="industries">
    <div class="industries__container _container">
      <div class="industries__header section__title">
        <div class="vertical-line"></div>
        <div class="industries__title">
          {{ $t("industries.title") }}
        </div>
      </div>
      <div class="industries__items">
        <div
          class="industries__item industry"
          v-for="item of $t('industries.items')"
          :key="item.id"
        >
          <div class="industry__title">
            {{ item.title }}
          </div>
          <div class="industry__text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.industries {
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: -14px 0;
    @include for-tablet-portrait-up {
      margin: -10px 0;
    }
  }
  .industry {
    width: calc((100% - 14px) / 2);
    height: 90px;

    padding: 16px 4px 7px 4px;

    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 14px 0;
    @include for-tablet-portrait-up {
      width: calc((100% - 42px) / 4);
      margin: 10px 0;
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 60px) / 4);
      height: 110px;

      padding: 20px 13px 7px 13px;
    }
    @include for-desktop-up {
      height: 130px;

      padding: 20px 38px 7px 38px;
    }
    &__title {
      font-family: "Monserrat", sans-serif;
      color: $black-color;
      text-align: center;

      @include P7();

      margin-bottom: 6px;

      @include for-tablet-landscape-up {
        font-size: 10px;
        line-height: 12px;

        margin-bottom: 10px;
      }
      @include for-desktop-up {
        margin-bottom: 21px;
      }
    }
    &__text {
      font-family: "Manrope", sans-serif;
      color: $main-color;
      text-align: center;

      @include P4();

      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        @include P2();
      }
    }
  }
}
</style>
