<template>
  <section class="managers">
    <div class="managers__container _container">
      <div class="managers__header section__title">
        <div class="vertical-line"></div>
        <div class="managers__title">
          {{ $t("managers_contacts.title") }}
        </div>
      </div>
      <div class="managers__content">
        <v-form class="managers__form"></v-form>
        <div class="managers-contacts">
          <div class="managers-contacts__content">
            <div class="managers-contacts__items">
              <div
                class="managers-contacts__item manager-contacts"
                v-for="item of $t('managers_contacts.items')"
                :key="item.id"
              >
                <!-- <div class="manager-contacts__photo"> -->
                  <img
                    class="manager-contacts__photo"
                    :src="require(`../assets/images/${item.image}`)"
                    alt="Manager"
                  />
                <!-- </div> -->
                <div class="manger-contacts__info">
                  <div class="manager-contacts__name">
                    {{ item.name }}
                  </div>
                  <div class="manager-contacts__position">
                    {{ item.position }}
                  </div>
                  <a
                    :href="`mailto:` + item.email"
                    class="manager-contacts__email contact-link"
                  >
                    <img src="../assets/icons/mail-dark.svg" alt="Mail icon" />
                    <span>
                      {{ item.email }}
                    </span>
                  </a>
                  <a
                    :href="`tel:` + item.phone"
                    class="manager-contacts__phone contact-link"
                  >
                    <img
                      src="../assets/icons/phone-dark.svg"
                      alt="Phone icon"
                    />
                    <span>{{ item.phone }} (Whatsapp/Viber) </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p class="managers-contacts__text">
            {{ $t("managers_contacts.text") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VForm from "../components/Form/VForm.vue";

export default {
  name: "VManagers",
  components: {
    VForm
  }
};
</script>

<style lang="scss">
.managers {
  &__container {
    padding-top: 40px;
    padding-bottom: 80px;
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 110px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 130px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-portrait-up {
      width: calc((100% - 14px) / 2);
    }
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
      width: calc((100% - 30px) / 2);
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__form {
    max-width: 340px;
    @include for-tablet-portrait-up {
      max-width: none;
    }
  }
  .form {
    &__label {
      color: $black-color;
    }
    .input,
    .textarea {
      width: 100%;
      height: 40px;

      padding: 12px 10px;

      border: 1px solid $gray-color;
      box-sizing: border-box;
      border-radius: 40px;

      background: none;

      font-family: "Manrope", sans-serif;
      color: $black-color;
      @include P6();

      @include for-tablet-landscape-up {
        height: 44px;
        @include P4();
        padding: 12px 14px;
      }
      @include for-desktop-up {
        height: 46px;
        padding: 12px 18px;
      }
    }
    .textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: 40px;
      max-height: 140px;
      @include for-tablet-landscape-up {
        min-height: 44px;
      }
      @include for-desktop-up {
        min-height: 46px;
      }
    }
    .checkbox {
      border: 1px solid $gray-color;
    }
    .label-checkbox {
      color: $black-color;
      a {
        color: $black-color;
      }
    }
  }
  &__form,
  &-contacts {
    @include for-tablet-portrait-up {
      width: calc((100% - 14px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 30px) / 2);
    }
  }
  &-contacts {
    &__text {
      font-family: "Manrope", sans-serif;
      @include P4();
      color: $super-dark-gray-color;
      margin-top: 20px;
      @include for-tablet-portrait-up {
        margin-top: 24px;
      }
      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        margin-top: 30px;
      }
    }
    &__items {
      display: flex;
      flex-direction: column;
    }
    .managers-contacts__item + .managers-contacts__item {
      margin-top: 14px;
      @include for-tablet-portrait-up {
        margin-top: 16px;
      }
      @include for-tablet-landscape-up {
        margin-top: 24px;
      }
    }
  }
  .manager-contacts {
    display: flex;
    margin-top: 40px;
    @include for-tablet-portrait-up {
      margin-top: 0;
    }
    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      border: 1px solid $gray-color;
      background: $white-color;
      border-radius: 5px;
      margin-right: 10px;
      object-fit: cover;
      @include for-tablet-landscape-up {
        width: 120px;
        height: 120px;
        margin-right: 18px;
      }
      @include for-desktop-up {
        width: 138px;
        height: 138px;
        margin-right: 20px;
      }
    }
    &__name {
      font-family: "Manrope", sans-serif;
      @include P4-bold();
      color: $super-dark-gray-color;
      margin-bottom: 2px;
      @include for-tablet-landscape-up {
        @include P2-bold();
        margin-bottom: 4px;
      }
      @include for-desktop-up {
        @include H6-bold();
      }
    }
    &__position {
      font-family: "Manrope", sans-serif;
      @include P6-light();
      color: $dark-gray-color;
      margin-bottom: 8px;
      @include for-tablet-landscape-up {
        @include P4-light();
        margin-bottom: 16px;
      }
      @include for-desktop-up {
        @include P3-light();
        margin-bottom: 14px;
      }
    }
    &__email {
      margin-bottom: 4px;
      @include for-tablet-landscape-up {
        margin-bottom: 6px;
      }
    }
    .contact-link {
      font-family: "Manrope", sans-serif;
      color: $super-dark-gray-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      @include P6();
      @include for-tablet-landscape-up {
        @include P4();
      }
      @include for-desktop-up {
        @include P3();
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        @include for-tablet-landscape-up {
          width: 16px;
          height: 16px;
        }
        @include for-desktop-up {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
