<template>
  <section class="statistics">
    <div class="statistics__container _container">
      <div class="statistics__header section__title">
        <div class="vertical-line"></div>
        <div class="statistics__title">
          {{ $t("statistics.title") }}
        </div>
      </div>
      <div class="statistics__items">
        <div
          class="statistics__item statistic"
          v-for="item of $t('statistics.items')"
          :key="item.id"
        >
          <div class="statistic__number">
            {{ item.number }}
          </div>
          <div class="statistic__text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.statistics {
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: -10px 0;
    @include for-tablet-portrait-up {
      margin: 0;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .statistic + .statistic {
    @include for-tablet-portrait-up {
      border-left: 1px solid #d6d6d6;
    }
  }
  .statistic {
    width: calc((100% - 14px) / 2);

    display: flex;
    flex-direction: column;

    margin: 10px 0;

    max-width: 163px;

    &:first-child {
      padding-left: 0;
    }
    @include for-tablet-portrait-up {
      align-items: flex-start;
      width: auto;
      max-width: calc((100% - 92px) / 5);
      padding-left: 24px;
      margin: 0;
    }
    @include for-tablet-landscape-up {
      max-width: calc((100% - 176px) / 5);
      padding-left: 44px;
    }
    @include for-desktop-up {
      max-width: calc((100% - 228px) / 5);
      padding-left: 59px;
    }
    &__number {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 53px;

      text-align: center;

      color: $main-color;

      margin-bottom: 4px;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        font-size: 44px;
        line-height: 58px;
      }
      @include for-desktop-up {
        font-size: 50px;
        line-height: 66px;
        margin-bottom: 6px;
      }
    }
    &__text {
      font-family: "Montserrat", sans-serif;

      @include P6();

      text-align: center;

      color: $black-color;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P4();
      }
      @include for-desktop-up {
        @include P3();
      }
    }
    &:nth-child(1){
      @include for-tablet-portrait-up {
        order: 1;
      }
    }
    &:nth-child(2){
      @include for-tablet-portrait-up {
        order: 2;
      }
    }
    &:nth-child(3){
      @include for-tablet-portrait-up {
        order: 4;
      }
    }
    &:nth-child(4){
      @include for-tablet-portrait-up {
        order: 5;
      }
    }
    &:nth-child(5){
      @include for-tablet-portrait-up {
        order: 3;
      }
    }
  }
}
</style>
