<template>
  <div id="app">
    <v-header></v-header>
    <router-view />
    <v-modal @close="closeModal" v-show="isModalOpen">
      <template #header v-if="isRequestCorrect">
        {{ $t("modal.good_title") }}
      </template>
      <template #header v-else>
        {{ $t("modal.bad_title") }}
      </template>
      <template #body v-if="isRequestCorrect">
        {{ $t("modal.good_content") }}
      </template>
      <template #body v-else>
        {{ $t("modal.bad_content") }}
      </template>
    </v-modal>
    <v-popup @close="closePopup" v-show="isCookiesOpen">
      <template #text>
        {{ $t("cookie_warning.text") }}
        <router-link to="/privacy-policy">
          {{ $t("cookie_warning.link_text") }}
        </router-link>
      </template>
    </v-popup>
    <v-footer></v-footer>
  </div>
</template>

<script>
import VHeader from "./components/VHeader";
import VFooter from "./components/VFooter";
import { mutations, store } from "./store/store";
import router from "./router/index";
import { languages } from "./store/languages";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    VHeader,
    VFooter,
    VModal: () => import("./components/Modal/VModal"),
    VPopup: () => import("./components/Modal/VPopup")
  },
  computed: {
    isModalOpen() {
      return store.isModalOpen;
    },
    isRequestCorrect() {
      return store.isRequestCorrect;
    },
    isCookiesOpen() {
      return store.isCookiesPopupOpen;
    },
    ...mapState({
      locale: state => state.locale
    })
  },
  methods: {
    closeModal() {
      router.push("/");
      mutations.closeModal();
      document.body.style.overflow = "auto";
    },
    closePopup() {
      mutations.closeCookiesPopup();
    }
  },
  created() {
    languages.dispatch("changeLocale", languages.state.locale);
  }
};
</script>

<style lang="scss">
a {
  color: $white-color;
  &:hover {
    text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
  }
}
</style>
