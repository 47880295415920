<template>
  <div class="form">
    <form @submit.prevent="submit">
      <div class="form__group">
        <label class="form__label">{{ $t("form.name.label") }}</label>
        <input
          name="name"
          class="input"
          type="text"
          v-model.trim="form.name"
          :placeholder="$t('form.name.placeholder')"
        />
      </div>
      <div class="form__group">
        <label class="form__label">{{ $t("form.phone.label") }}</label>
        <input
          name="phone"
          class="input"
          type="text"
          v-model.trim="form.phone"
          :placeholder="$t('form.phone.placeholder')"
        />
      </div>
      <div class="form__group">
        <label class="form__label">{{ $t("form.email.label") }}</label>
        <input
          name="email"
          class="input"
          type="text"
          v-model.trim="form.email"
          :placeholder="$t('form.email.placeholder')"
        />
      </div>
      <div class="form__group">
        <label class="form__label">{{ $t("form.message.label") }}</label>
        <textarea
          name="comment"
          v-model.trim="form.comment"
          :placeholder="$t('form.message.placeholder')"
          @input="textAreaAdjust"
          class="textarea"
        ></textarea>
      </div>
      <div class="form__group form__group-checkbox">
        <input type="checkbox" class="checkbox" v-model="isChecked" />
        <label class="label-checkbox">
          {{ $t("form.agreement_text") }}
          <router-link to="/privacy-policy">
            {{ $t("form.link_to_policy") }}
          </router-link>
        </label>
      </div>
      <div class="form__button">
        <button type="submit" :disabled="!isChecked">
          {{ $t("form.button") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formsServices from "../../services/forms.services.js";
import { mutations } from "../../store/store";

export default {
  data: () => ({
    form: {
      name: "",
      phone: "",
      email: "",
      comment: ""
    },
    isChecked: false
  }),
  methods: {
    async submit() {
      await formsServices.sendForm(
        this.form.name,
        this.form.phone,
        this.form.email,
        this.form.comment
      );
    },
    closeModal() {
      mutations.closeModal();
      document.body.style.overflow = "auto";
    },
    textAreaAdjust: function(e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  },
  mounted() {
    let textarea = document.querySelectorAll(".textarea");
    textarea.forEach(item => {
      item.setAttribute(
        "style",
        "height:" + textarea.scrollHeight + "px;overflow-y:hidden;"
      );
    });
  }
};
</script>

<style lang="scss">
.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  &::before {
    content: url("../../assets/icons/checkbox.svg");
    position: absolute;
    right: 50%;
    bottom: 8px;
    transform: translate(50%, 50%);
    opacity: 0;
    transition: 0.1s ease-in;
  }
  &:checked::before {
    opacity: 1;
  }
}
.label-checkbox {
  font-family: "Manrope", sans-serif;
  @include P6();
  @include for-tablet-landscape-up() {
    @include P4();
  }
}
.checkbox + .label-checkbox {
  margin-left: 10px;
}
.form {
  &__group {
    display: flex;
    flex-direction: column;
    &.form__group-checkbox {
      flex-direction: row;
    }
  }
  &__group + &__group {
    margin-top: 22px;
    @include for-tablet-landscape-up {
      margin-top: 24px;
    }
    @include for-desktop-up {
      margin-top: 28px;
    }
  }
  &__label {
    font-family: "Manrope", sans-serif;
    @include P4();
    margin-bottom: 4px;
    @include for-tablet-landscape-up {
      @include P3();
      margin-bottom: 6px;
    }
    @include for-desktop-up {
      @include P2();
      margin-bottom: 10px;
    }
  }
  &__button {
    margin-top: 24px;
    @include for-tablet-landscape-up {
      margin-top: 30px;
    }
    @include for-desktop-up {
      margin-top: 36px;
    }
    button {
      font-family: "Manrope", sans-serif;
      @include P4();
      @include button();
      width: 180px;
      height: 42px;
      @include for-tablet-landscape-up {
        @include P3();
        width: 200px;
        height: 46px;
      }
      @include for-desktop-up {
        width: 200px;
        height: 46px;
      }
    }
  }
}
</style>
