<template>
  <section class="offer offer__section">
    <div class="offer__container _container">
      <div class="offer__text">
        <h2 class="offer__title">
          {{ $t("offer.title") }}
        </h2>
        <div class="offer__subtitle">
          {{ $t("offer.subtitle") }}
        </div>
      </div>
      <div class="offer__card card">
        <div class="card__title">
          {{ $t("offer.card_title") }}
        </div>
        <v-form></v-form>
      </div>
    </div>
  </section>
</template>

<script>
import VForm from "../components/Form/VForm";

export default {
  name: "VOffer",
  components: {
    VForm
  }
};
</script>

<style lang="scss">
.offer {
  width: 100%;
  background: url("../assets/images/offer-background-desktop.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 60px;
    padding-top: 26px;
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-top: 70px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include for-tablet-landscape-up {
      margin-top: 80px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include for-desktop-up {
      margin-top: 100px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__title {
    font-family: "Merriweather", serif;
    color: $white-color;
    text-align: center;
    @include P2();
    @include for-tablet-portrait-up {
      text-align: left;
    }
    @include for-tablet-landscape-up {
      @include H6();
    }
    @include for-desktop-up {
      @include H2();
    }
  }
  &__subtitle {
    font-family: "Manrope", sans-serif;
    color: $gray-color;
    text-align: center;
    @include P4();
    @include for-tablet-portrait-up {
      text-align: left;
    }
    @include for-tablet-landscape-up {
      @include P3();
    }
    @include for-desktop-up {
      @include P2();
    }
  }
  &__title + &__subtitle {
    margin-top: 12px;
    @include for-tablet-portrait-up {
      margin-top: 14px;
    }
    @include for-tablet-landscape-up {
      margin-top: 26px;
    }
    @include for-desktop-up {
      margin-top: 46px;
    }
  }
  &__text + &__card {
    margin-top: 30px;
    @include for-tablet-portrait-up {
      margin-top: 0;
      margin-left: 22px;
    }
    @include for-tablet-landscape-up {
      margin-left: 37px;
    }
    @include for-desktop-up {
      margin-left: 132px;
    }
  }
  &__card {
    background: rgba(26, 26, 26, 0.7);
    backdrop-filter: blur(24px);
    border-radius: 30px;
    padding: 24px 12px;
    width: 100%;
    max-width: 339px;
    @include for-tablet-portrait-up {
      border-radius: 40px;
      padding: 28px 16px;
      width: 359px;
      min-width: 359px;
      max-width: none;
    }
    @include for-tablet-landscape-up {
      padding: 36px 24px;
      width: 478px;
      min-width: 478px;
    }
    @include for-desktop-up {
      width: 490px;
      min-width: 490px;
    }
    .card__title {
      font-family: "Manrope", sans-serif;
      color: $white-color;
      text-align: center;
      @include P2();
      margin-bottom: 26px;
      @include for-tablet-landscape-up {
        @include P1();
        margin-bottom: 30px;
      }
      @include for-desktop-up {
        @include H6();
        margin-bottom: 36px;
      }
    }
    .form {
      &__label {
        color: $white-color;
      }
      .input,
      .textarea {
        width: 100%;
        height: 40px;

        padding: 12px 10px;

        border: 1px solid #4a4a4a;
        box-sizing: border-box;
        border-radius: 40px;

        background: none;

        font-family: "Manrope", sans-serif;
        color: $white-color;
        @include P6();

        @include for-tablet-landscape-up {
          height: 44px;
          @include P4();
          padding: 12px 14px;
        }
        @include for-desktop-up {
          height: 46px;
          padding: 12px 18px;
        }
      }
      .textarea {
        max-width: 100%;
        min-width: 100%;
        min-height: 40px;
        max-height: 140px;
        @include for-tablet-landscape-up {
          min-height: 44px;
        }
        @include for-desktop-up {
          min-height: 46px;
        }
      }
      .checkbox {
        border: 1px solid #4a4a4a;
      }
      .label-checkbox {
        color: $white-color;
        a {
          color: $white-color;
        }
      }
      &__button {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
