<template>
  <header class="header">
    <div class="header__content _container">
      <h1 class="header__title title-hidden">Komtec</h1>
      <div class="header__logo logo">
        <router-link to="/" class="menu__logo">
          <img
            src="../assets/images/Logo.svg"
            alt="Komtec logo"
            class="menu__logo-image"
          />
        </router-link>
      </div>
      <div class="header__phone">
        <a href="tel:+48 571 391 131">+48 571 391 131</a>
      </div>
      <div class="header__menu menu">
        <v-burger class="menu__icon icon-menu" v-if="isMobileView"></v-burger>
        <v-sidebar v-if="isMobileView"></v-sidebar>
        <template v-else>
          <v-navbar></v-navbar>
        </template>
      </div>
      <v-language-switcher
        class="desktop"
        :isMobile="false"
        v-if="!isMobileView"
      />
    </div>
  </header>
</template>

<script>
import VBurger from "../components/Menu/VBurger";
import VNavbar from "../components/Menu/VNavbar";
import VSidebar from "../components/Menu/VSidebar";
import VLanguageSwitcher from "../components/Menu/VLanguageSwitcher"

export default {
  name: "VHeader",
  components: { VBurger, VNavbar, VSidebar, VLanguageSwitcher },
  data: () => ({
    isMobileView: false
  }),
  methods: {
    handleView() {
      this.isMobileView = window.innerWidth < 768;
    }
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  }
};
</script>

<style lang="scss">
.title-hidden {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  max-width: 100%;
  background: $white-color;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.05);
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    @include for-tablet-portrait-up {
      height: 70px;
    }
    @include for-tablet-landscape-up {
      height: 80px;
    }
    @include for-desktop-up {
      height: 100px;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
  }
  &__phone {
    a {
      color: $main-color;
      font-family: "Manrope", sans-serif;
      font-size: 14px;
      line-height: 19px;
      text-decoration: none;
      font-weight: 600;
      @include for-tablet-landscape-up {
        font-size: 16px;
        line-height: 22px;
      }
      @include for-desktop-up {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}
.menu {
  &__logo-image {
      width: 111px;
      height: 36px;
    @include for-tablet-portrait-up {
      width: 126px;
      height: 42px;
    }
    @include for-tablet-landscape-up {
      width: 150px;
      height: 50px;
    }
    @include for-desktop-up {
      width: 164px;
      height: 54px;
    }
  }
}
</style>
