<template>
  <div
    class="language__switcher"
    @mouseover="!isMobile ? showLanguageList() : {}"
    @mouseleave="!isMobile ? hideLanguageList() : {}"
  >
    <div class="current-language__item" @click="isActive = !isActive">
      <img src="../../assets/icons/globe.svg" alt="Globe" v-if="!isMobile" />
      <span class="current-language"> {{ lang }} </span>
      <span v-if="isMobile" :class="['arrow', isActive ? 'top' : 'bottom']">
      </span>
    </div>
    <transition name="language-list">
      <div class="language__list" v-if="isActive">
        <ul>
          <li
            v-for="locale in locales"
            :key="locale.value"
            @click="switchLocale(locale.value)"
          >
            {{ locale.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
// import i18n from "@/i18n"
import { languages } from "../../store/languages";

export default {
  data: () => ({
    isActive: false,
    locales: [
      {
        name: "Русский (RU)",
        value: "ru"
      },
      {
        name: "English (EN)",
        value: "en"
      },
      {
        name: "Polski (PL)",
        value: "pl"
      }
    ]
  }),
  props: {
    isMobile: {
      type: Boolean
    }
  },
  computed: {
    lang: {
      get: function() {
        return languages.state.locale;
      }
    }
  },
  methods: {
    switchLocale(newVal) {
      languages.dispatch("changeLocale", newVal);
    },
    showLanguageList() {
      this.isActive = true;
    },
    hideLanguageList() {
      this.isActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.language-list-enter-active,
.language-list-leave-active {
  transition: transform 0.5s ease;
}
.language-list-enter,
.language-list-leave-to {
  transform: translateY(-20px);
  transition: transform 0.5s ease-in;
}
.language__switcher.desktop {
  position: relative;
  display: flex;
  align-items: center;
  .current-language__item {
    display: flex;
    align-items: center;
    .current-language {
      font-family: "Manrope", sans-serif;
      text-transform: uppercase;
      @include for-tablet-portrait-up {
        @include P4();
        padding-left: 6px;
      }
      @include for-tablet-landscape-up {
        @include P3();
        padding-left: 8px;
      }
      @include for-desktop-up {
        @include P1();
      }
    }
    &:hover {
      cursor: pointer;
    }
    img{
      @include for-tablet-portrait-up {
        width: 18px;
        height: 18px;
      }
      // @include for-tablet-landscape-up {
      //   width: 18px;
      //   height: 18px;
      // }
      @include for-desktop-up {
        width: 20px;
        height: 20px;
      }
    }
  }
  .language__list {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 16px;
    min-width: 147px;
    display: flex;
    flex-direction: column;
    @include for-tablet-landscape-up {
      min-width: 192px;
    }
    ul {
      padding: 0;
    }
    li {
      font-family: "Manrope", sans-serif;
      text-decoration: none;
      list-style: none;
      color: var(--white-color);
      @include for-tablet-portrait-up {
        @include P4();
      }
      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        @include P2();
      }
      &:hover {
        cursor: pointer;
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
    }
    li + li {
      padding-top: 12px;
      @include for-tablet-landscape-up {
        padding-top: 14px;
      }
    }
  }
}
.language__switcher.mobile {
  margin-top: 40px;
  .current-language__item {
    margin-bottom: 12px;
    font-family: "Manrope", sans-serif;
    color: $white-color;
    text-transform: uppercase;
    @include P2();
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
      text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      cursor: pointer;
    }
    span:nth-child(1) {
      margin-right: 8px;
    }
  }
  .language__list {
    display: flex;
    flex-direction: column;
    ul {
      padding: 0;
    }
    li {
      font-family: "Manrope", sans-serif;
      @include P3();
      text-decoration: none;
      color: $white-color;
      list-style: none;
      font-family: "Manrope", sans-serif;
      &:hover {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
    }
    li + li {
      padding-top: 10px;
    }
  }
}
.arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid $white-color;
  border-right: 2px solid $white-color;
  transition: all 0.5s ease;
  display: inline-block;
  &.top {
    transform: rotate(-45deg);
  }
  &.bottom {
    transform: rotate(135deg);
  }
}
</style>
