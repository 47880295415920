import Vue from 'vue'
import App from './App.vue'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

Vue.config.productionTip = false

import i18n from './i18n'
import router from './router'
import axios from 'axios'

import PrimeVue from 'primevue/config'
Vue.use(PrimeVue)

Vue.prototype.$http = axios

new Vue({
  render: h => h(App),
  router,
  // store,
  i18n
}).$mount('#app')
