<template>
  <section class="consultation">
    <div class="consultation__container _container">
      <div class="consultation__header section__title">
        <div class="vertical-line"></div>
        <div class="consultation__title">
          {{ $t("consultation.title") }}
        </div>
      </div>
      <div class="consultation__content">
        <v-form class="consultation__form"></v-form>
        <div
          class="consultation__image"
          v-for="item of $t('consultation.images')"
          :key="item.id"
        >
          <img :src="require(`../assets/icons/${item.image}`)" alt="Question" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VForm from "../components/Form/VForm.vue";

export default {
  name: "VConsultation",
  components: {
    VForm
  }
};
</script>

<style lang="scss">
.consultation {
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-portrait-up {
      width: calc((100% - 14px) / 2);
    }
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
      width: calc((100% - 30px) / 2);
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__form,
  &__image {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      width: calc((100% - 14px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 30px) / 2);
    }
  }
  &__form {
    max-width: 340px;
    @include for-tablet-portrait-up {
      max-width: none;
    }
  }
  &__image {
    display: none;
    @include for-tablet-portrait-up {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      img {
        height: 100px;
        width: 100px;

        @include for-tablet-portrait-up {
          height: 140px;
          width: 140px;
        }
        @include for-tablet-landscape-up {
          height: 173px;
          width: 173px;
        }
      }
    }
  }
  .form {
    &__label {
      color: $black-color;
    }
    .input,
    .textarea {
      width: 100%;
      height: 40px;

      padding: 12px 10px;

      border: 1px solid $gray-color;
      box-sizing: border-box;
      border-radius: 40px;

      background: none;

      font-family: "Manrope", sans-serif;
      color: $black-color;
      @include P6();

      @include for-tablet-landscape-up {
        height: 44px;
        @include P4();
        padding: 12px 14px;
      }
      @include for-desktop-up {
        height: 46px;
        padding: 12px 18px;
      }
    }
    .textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: 40px;
      max-height: 140px;
      @include for-tablet-landscape-up {
        min-height: 44px;
      }
      @include for-desktop-up {
        min-height: 46px;
      }
    }
    .checkbox {
      border: 1px solid $gray-color;
    }
    .label-checkbox {
      color: $black-color;
      a {
        color: $black-color;
      }
    }
  }
}
</style>
