<template>
  <main class="home home-page">
    <v-offer id="offer"></v-offer>
    <v-services id="services"></v-services>
    <v-advantages id="advantages"></v-advantages>
    <v-portfolio id="portfolio"></v-portfolio>
    <v-industries id="industries"></v-industries>
    <v-consultation id="consultation"></v-consultation>
    <v-statistics id="statistics"></v-statistics>
    <v-about-company id="about-company"></v-about-company>
    <v-managers id="contacts"></v-managers>
  </main>
</template>

<script>
import VOffer from '../components/VOffer.vue'
import VAdvantages from '../components/VAdvantages.vue'
import VIndustries from '../components/VIndustries.vue'
import VAboutCompany from '../components/VAboutCompany.vue'
import VStatistics from '../components/VStatistics.vue'
import VConsultation from '../components/VConsultation.vue'
import VManagers from '../components/VManagers.vue'
import VServices from '../components/VServices.vue'
import VPortfolio from '../components/VPortfolio.vue'

export default {
  name: 'Home',
  components: {
    VOffer,
    VAdvantages,
    VIndustries,
    VAboutCompany,
    VStatistics,
    VConsultation,
    VManagers,
    VServices,
    VPortfolio
  }
}
</script>
