<template>
  <div class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <ul class="menu__list">
          <li
            v-for="item of $t('header.nav')"
            :key="item.id"
          >
            <template v-if="item.submenu">

              <div @click="isActive = !isActive">
                <span class="menu__link has-children">{{ item.name }}</span>
                <span :class="['arrow', isActive ? 'top' : 'bottom']"> </span>
              </div>
              <transition name="submenu-list">
                <div class="menu__list submenu" v-show="isActive">
                  <router-link
                    v-for="x of item.submenu"
                    :key="x.id"
                    :to="{ path: `${x.link}`, hash: `${x.hash}` }"
                    class="menu__link"
                    >{{ x.name }}</router-link
                  >
                </div>
              </transition>
            </template>
            <template v-else>
              <router-link
                :to="{ path: `${item.link}`, hash: `${item.hash}` }"
                class="menu__link"
                exact
              >
                {{ item.name }}
              </router-link>
            </template>
          </li>
        </ul>
        <v-language-switcher class="mobile" :isMobile="true" />
      </div>
    </transition>
  </div>
</template>

<script>
import { store, mutations } from "../../store/store.js";
import VLanguageSwitcher from "../Menu/VLanguageSwitcher";
export default {
  components: { VLanguageSwitcher },
  data: function() {
    return {
      isActive: false
    };
  },
  methods: {
    closeSidebarPanel() {
      mutations.toggleNav();
      document.body.style.overflow = store.isNavOpen ? "hidden" : "";
    }
  },
  computed: {
    isPanelOpen() {
      return store.isNavOpen;
    }
  }
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background: rgba(7, 7, 7, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 10;
  padding: 58px 16px 58px 16px;
  max-width: 360px;
  width: 100%;
  .submenu {
    margin-top: 8px;
    .menu__link + .menu__link {
      margin-top: 10px;
    }
  }
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li + li {
      margin-top: 16px;
    }
    .menu__link {
      font-family: "Manrope", sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: $white-color;
      text-decoration: none;
      &.has-children {
        margin-right: 10px;
      }
      &:hover {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
      &.router-link-exact-active::after {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
      &.router-link-exact-active::before {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
      &:visited {
        text-decoration: none;
      }
    }
  }
  .arrow {
    width: 10px;
    height: 10px;
    border-top: 2px solid $white-color;
    border-right: 2px solid $white-color;
    transition: all 0.5s ease;
    display: inline-block;
    &.top {
      transform: rotate(-45deg);
    }
    &.bottom {
      transform: rotate(135deg);
    }
  }
}
</style>
