<template>
  <section class="our-advantages">
    <div class="our-advantages__container _container">
      <div class="our-advantages__header section__title">
        <div class="vertical-line"></div>
        <div class="our-advantages__title">
          {{ $t("advantages.title") }}
        </div>
      </div>
      <div class="our-advantages__items">
        <div
          class="our-advantages__item advantage"
          v-for="item of $t('advantages.items')"
          :key="item.id"
        >
          <img
            :src="require(`../assets/icons/${item.image}`)"
            :alt="item.title"
            class="advantage__image"
          />
          <div class="advantage__title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.our-advantages {
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
    @include for-tablet-landscape-up {
      padding-top: 55px;
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 34px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px 0;
    @include for-tablet-portrait-up {
      margin: 0;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .advantage + .advantage {
    @include for-tablet-portrait-up {
      border-left: 1px solid #d6d6d6;
    }
  }
  .advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc((100% - 14px)/2);
    max-width: 160px;
    margin: 10px 0;
    &:first-child{
      padding-left: 0;
    }
    @include for-tablet-portrait-up {
      align-items: flex-start;
      max-width: calc((100% - 75px) / 6);
      padding-left: 15px;
      margin: 0;
    }
    @include for-tablet-landscape-up {
      max-width: calc((100% - 150px) / 6);
      padding-left: 30px;
    }
    @include for-desktop-up {
      max-width: calc((100% - 190px) / 6);
      padding-left: 38px;
    }
    &__title {
      font-family: "Manrope", sans-serif;
      @include P6();
      text-align: center;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P4();
      }
      @include for-desktop-up {
        @include P3();
      }
    }
    &__image {
      margin-bottom: 10px;
      height: 40px;
      width: 40px;
      @include for-tablet-landscape-up {
        height: 46px;
        width: 46px;
        margin-bottom: 12px;
      }
      @include for-desktop-up {
        height: 50px;
        width: 50px;
        margin-bottom: 18px;
      }
    }
  }
}
</style>
